<!-- Modal -->

<div class="modal-header" *ngIf="createActionDdl" style="padding: 0 !important;">
    <div class="row ">
        <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="container-fluid">
                <div class="row cre_header">
                    <div class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                        <h6>Select a Category<img style="margin-left: 10px;" src="assets/images/target_vec.png" alt=""></h6>
                    </div>
                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 cur" style="text-align: right;" data-dismiss="modal" (click)="closeModal()">
                        <img src="assets/images/cls_icon.png" alt="close">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner> -->
<app-loader-animation *ngIf="isLoading"></app-loader-animation>
<div class="modal-body" style="padding: 0rem 1rem 1.5rem;background-color: #e8ebee;border-radius: 3px;" *ngIf="createActionDdl">
    <div class="container">

        <br>
        <br>
        <div class="row" *ngIf="createActionDdl">
            <div class="col-10 col-sm-11 col-md-10 col-lg-12 col-xl-12">
                <!-- <select class="rec_dropdwn cur"  [ngModel]="selectedDevice"
					style="font-family: 'Gotham Book Regular' !important;" (change)="onChange($event)">
					<option value="" >--Select One--</option>
					<optgroup [attr.label]="item.ACPT_DESC != null?item.ACPT_NAME:''"
						*ngFor="let item of actionPlanNames"
						[ngStyle]="{'display': item.ACPT_DESC != null ? 'block' : 'none' }"
						style="text-transform: capitalize;">

						<option [value]="y.DESC1" [attr.data-actionName]="item.ACPT_NAME"
							[attr.data-labelName]="y.ACPT_DESC" [attr.data-labelValue]="y.ACPT_ID"
							*ngFor="let y of convertStoresXml(item.ACPT_DESC)">
							{{y.ACPT_DESC}}
						</option>

					</optgroup>

				</select> -->
                <ng-select2 [data]="actionPlanNames" [value]="actionPlanTitle" [width]="750" (valueChanged)="changed($event)" placeholder="--Select One--"></ng-select2>
            </div>
        </div>
    </div>
</div>


<div class="modal-header" style="padding: 0 !important;" *ngIf="createAction && !isLoading">
    <div class="row ">
        <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="container-fluid">
                <div class="row cre_header">
                    <div class="col-10 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                        <h6 style="text-transform: uppercase;">{{actionPlantype == 'A' ||actionPlantype == 'G'?'Create ActionPlan':'Edit ActionPlan'}} &nbsp;&nbsp;<img src="assets/images/target_vec.png" alt=""></h6>
                    </div>
                    <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 cur" style="text-align: right;" data-dismiss="modal" (click)="closeModal()">
                        <img src="assets/images/cls_icon.png" alt="close" (click)="closeModal()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-body" style="padding: 0rem 0rem 1.5rem;background-color: #e8ebee;" *ngIf="!isLoading">
    <div class="container" *ngIf="!isLoading">
        <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="createAction">

            <div class="row targ_area">
                <div class="col-xl-2"><span>Target Area:</span></div>
                <div class="col-xl-8 px-xl-0">
                    <label *ngIf="actionPlantype=='E'">{{actionPlanTitle}}</label>
                    <!-- <select *ngIf="actionPlantype!='E'" class="rec_dropdwn cur"
						style="font-family: 'Gotham Book Regular' !important;" (change)="onChange($event)">
						<option value="">--Select One--</option>
						<optgroup [attr.label]="item.ACPT_DESC != null?item.ACPT_NAME:''"
							*ngFor="let item of actionPlanNames"
							[ngStyle]="{'display': item.ACPT_DESC != null ? 'block' : 'none' }" class="optgrp"
							style="text-transform: capitalize !important;margin: .4rem 0">

							<option [value]="y.DESC1" [attr.data-actionName]="item.ACPT_NAME"
								[attr.data-labelValue]="y.ACPT_ID" [attr.data-labelName]="y.ACPT_DESC"
								*ngFor="let y of convertStoresXml(item.ACPT_DESC)"
								[selected]="y.ACPT_DESC == actionPlanTitle ">
								{{y.ACPT_DESC}}
							</option>

						</optgroup>

					</select> -->
                    <ng-select2 *ngIf="actionPlantype!='E'" [data]="actionPlanNames" [value]="selectedValue" [width]="300" (valueChanged)="changed($event)" placeholder="--Select One--"></ng-select2>

                </div>
                <div class="col-xl-2" style="text-align: end;" *ngIf="actionPlantype=='E'"> <img class="cur" src="assets/images/edit_np.png" (click)="edittopvaluActionPlan(pop,'S','Changing Values will close current action plan and create a new one. Do you want to continue?')">
                </div>
            </div>
            <div class="row values_" *ngIf="actionPlanValuesFromService == undefined">
                <div class="col">
                    <div style="display: grid;">
                        <!-- <span>Start Value:</span> -->
                        <label>Loading...</label>
                    </div>
                </div>
            </div>
            <div class="row values_" *ngIf="actionPlanValuesFromService != undefined">
                <!-- <div class="col" style="border-right: 1px solid #ccc;">
					<div style="display: grid;">
						<span>Current Value</span>
						<label>
							
							---
						</label>
					</div>
				</div> -->
                <div class="col" style="border-right: 1px solid #ccc;">
                    <div style="display: grid;text-align: center;">
                        <span>Start Value</span>
                        <label><span
								style="font-size:1rem;font-weight:600;">{{units?.includes('D')?'$':''}}</span>{{toFixedSpecial(startValue) != null ?toFixedSpecial(startValue) :"---"}}<span
								style="font-size:1rem;font-weight:600;">{{units?.includes('%')?'%':''}}</span></label>
                    </div>

                </div>
                <div class="col" style="border-right: 1px solid #ccc;">
                    <div style="display: grid;text-align: center;">
                        <span>{{apActualValue?.short_month[0]}} {{apActualValue?.year[0]}}</span>
                        <label><span
								style="font-size:1rem;font-weight:600;">{{units?.includes('D')?'$':''}}</span>{{toFixedSpecial(actionPlanActualValue) != null ?toFixedSpecial(actionPlanActualValue) :"---"}}<span
								style="font-size:1rem;font-weight:600;">{{units?.includes('%')?'%':''}}</span></label>
                    </div>

                </div>
                <!-- <div class="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1"></div> -->
                <div class="col">
                    <!-- <div style="display: grid;">
												<span>Target:</span> -->
                    <!-- <label>{{actionPlanValuesFromService[0]?.targetvalue != null?actionPlanValuesFromService[0]?.targetvalue :'---'}}</label> -->
                    <!-- <input type="text" [(ngModel)]="taragetValue" maxlength="50" class="form-control"
													style="text-align: center;" (ngModelChange)="valuechange($event)"> -->
                    <!-- </div> -->
                    <div style="display: grid;cursor: pointer;" *ngIf="ShowAvgs">
                        <!-- <span>6 Months Average:</span> -->
                        <!-- <div class="col-md-2"></div> -->
                        <!-- <div class="col-md-8 ml-3"> -->
                        <!-- <select class="avg_dropdwn " (change)='onAverageSelected($event)'
							[(ngModel)]="avgValueSelected">
							<option value='3'>3 Months Average</option>
							<option value='6'>6 Months Average</option>
							<option value='9'>9 Months Average</option>
						</select> -->
                        <!-- </div> -->
                        <!-- <div class="col-md-2"></div> -->
                        <span (click)="this.ShowAvgs=!this.ShowAvgs">{{(avgValueSelected == '3') ? '3 Month Average':(avgValueSelected == '6') ? '6 Month Average':'9 Month Average'}}
							<img src="assets/images/act_drp_arw.png" />
						</span>
                        <label>
							<span
								style="font-size:1rem;font-weight:600;">{{units?.includes('D')?'$':''}}</span>{{toFixedSpecial(MonthsAvgValue) != null ? toFixedSpecial(MonthsAvgValue) :'---' }}<span
								style="font-size:1rem;font-weight:600;">{{units?.includes('%')?'%':''}}</span></label>
                    </div>
                    <div style="display: grid;position: fixed; z-index: 999999;" *ngIf="!ShowAvgs" class="AvgDDl" [ngClass]="{hide: ShowAvgs}">
                        <li *ngFor="let option of Averages;let i = index" (click)="this.ShowAvgs=!this.ShowAvgs;onAverageSelected(option.AvgId)" [ngStyle]="avgValueSelected == option.AvgId && {'background-color': 'rgb(204 201 201)'}">
                            <a class="col-9 pl-xl-1" [ngStyle]="avgValueSelected == option.AvgId && {'color':'#ffffff !impotant'}">
                                <label style="font-size: .8rem;font-family: 'Gotham Book Regular' !important;">
									{{option.AvgName}} </label>
                                <img *ngIf="i==0" src="assets/images/act_drp_arw.png" />
                            </a>
                        </li>
                    </div>
                    <div style="height: 1000%;width: 1000%;position: fixed;left: -100%;top: -100%;bottom: -100%;z-index: 99999;" (click)="this.ShowAvgs = !this.ShowAvgs;" [ngClass]="{hide: ShowAvgs}"></div>
                </div>
                <!-- <div class="col-0 col-sm-0 col-md-0 col-lg-1 col-xl-1"></div> -->

                <div class="col pr-0" style="border-left: 1px solid #ccc;">
                    <div style="display: grid;" *ngIf="actionPlantype != 'E' || Edittopvalue">
                        <span>Target </span>
                        <label style="display: flex;margin-left: 0px;"><input type="text" [(ngModel)]="taragetValue" maxlength="10" class="form-control"
								style="text-align: center;font-size: 1rem;float: left;width:83%" appUsdOnly
								[actionPlanTitle]="actionPlanTitle">
							<img src="assets/images/refresh.png" (click)="valuechange(taragetValue)"
								style="height: 1.5rem;float: left;margin-top: .4rem;margin-left: .19rem;" />
						</label>
                    </div>
                    <div *ngIf="actionPlantype == 'E' && !Edittopvalue">
                        <span>Target </span>
                        <label style="border:1px solid #ced4da;border-radius: 0.3rem;width:151px;"><span
								style="font-size:1rem;font-family:'Gotham\ Bold' !important;;">{{taragetValue}}</span></label>
                    </div>
                </div>
            </div>
            <div (ngSubmit)="(actionPlantype != 'E')?onSubmit():onEditSubmit(pop,'T','Do you want to Mark old Action Plan as “Closed”, “Completed” or cancel this edit?')" class="row justify-content-center" style="background-color: #e8ebee;">

                <div class="col-md-12">
                    <div class="row py-4 justify-content-center" style="border-bottom: 1px solid #ccc;">
                        <div class="col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 trgt_value">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <div class="row" style="justify-content: center; display: flex; align-items: center;">
                                        <div class="col-5 col-sm-5 col-md-5 col-lg-4 col-xl-3 px-0 "><span>Target
												Date:</span></div>
                                        <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                            <!-- <input type="date" class="form-control" id="targetDate" name="targetDate"
												[(ngModel)]="targetDate" formControlName="targetDate"
												style="font-family:'Gotham\ Black';font-size:0.9rem;height: calc(2.25rem + 12px);"> -->
                                            <dp-date-picker theme="dp-material" class="form-control" [(ngModel)]="targetDate" [config]="datePickerConfig" required="true" style="font-family:'Gotham\ Black';font-size:0.9rem;height: calc(2.25rem + 4px);">
                                            </dp-date-picker>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <div class="row" *ngIf="actionPlanTitle != '# New Fleet Units'" style="justify-content: center; display: flex; align-items: center;">
                                        <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-6 px-0 "><span>Potential
											<span *ngIf="APsection!='cash' && APsection!='gross'"> Net</span>	
                                            <span *ngIf="APsection=='cash'"> Cash</span>	
                                            <span *ngIf="APsection=='gross'"> Gross</span>	
												Increase:</span></div> &nbsp;
                                        <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-5 pl-lg-0" *ngIf="netIncreaseLoader">
                                            <div class="loader"></div>
                                        </div>
                                        <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-5 pl-lg-0" *ngIf="!netIncreaseLoader">
                                            <h3 title="{{netIncrease | currencyusd:actionPlanTitle }}">
                                                {{netIncrease | currencyusd:actionPlanTitle }}</h3>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-11 px-5 py-3">
                    <!-- <div class="row assigner" style="padding: 3px 0;align-items: center;">
						<div class="col-md-2">
							<span>Created By:</span>
						</div>
						<div class="col-md-4">
							<div class="c_dropdown">
								<ul>
									<li>
										<div class="pic"></div>
										<div class="des" *ngIf="userInfo != undefined">
											<div class="name">{{userInfo.u_first_name}} {{userInfo.u_last_name}}</div>
											<div class="role">General Manager</div>
										</div>
									</li>
									<span></span>
								</ul>
							</div>
						</div>
						<div class="col-md-4 pl-lg-0">
							<input type="date" [min]="createdDate | date:'yyyy-MM-dd'" class="form-control"
								id="createdDate" name="createdDate" [ngModel]="createdDate | date:'yyyy-MM-dd'"
								formControlName="createdDate">
						</div>
					</div> -->
                    <!-- <div class="row assigner" style="padding: 3px 0;align-items: center;">
						<div class="col-md-2">
							<span>Approved By:</span>
						</div>
						<div class="col-md-4">
							<div class="c_dropdown">
								<ul>
									<li>
										<div class="pic"></div>
										<div class="des">
											<div class="name">David Spisak</div>
											<div class="role">General Manager</div>
										</div>
									</li>
									<span></span>
								</ul>
							</div>
						</div>
						<div class="col-md-3 pl-lg-0">
							<input type="date" class="form-control">
						</div>
					</div> -->
                    <div class="row assigner" style="padding: 3px 0;align-items:center;" *ngIf="actionPlantype=='E'">
                        <div class="col-md-1" style="padding-right: 5.3rem;">
                            <span>Status:</span>
                        </div>
                        &nbsp;&nbsp;&nbsp;
                        <div class="col-md-5 pl-0">
                            <div class="col-md-12 pl-0">
                                <select class="form-control " [(ngModel)]="statusValue" [ngClass]="(userInfo.id==actionPlanCreatedId || userInfo.id== assignee?.sc_id)?'brand-dropdown':'inactive_events brand-dropdown_off'" (change)='(userInfo.id==actionPlanCreatedId || userInfo.id== assignee?.sc_id)?onOptionsSelected($event):$event.preventDefault()'>
									<option value='1'>Active</option>
									<option value='2'>Completed</option>
									<option value='3'>Cancelled</option>
									<option value='4'>Achieved</option>
								</select>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="this.actionPlantype =='E'">
                        <div class="row assigner" style="padding: 3px 0;align-items: center;">
                            <div class="col-md-2 py-0">
                                <span>Created By:</span>
                            </div>
                            <div class="col-md-5 pl-0">
                                <div class="col-md-12 pl-0">
                                    <div class="f_dropdown">
                                        <ul>
                                            <li>
                                                <div class="">
                                                    <img class="pic" src="{{userprofileimageurl}}{{this.editActionPlanArray[0]?.createdbyimg}}" onError="this.src='../../../assets/images/default-img.jpg'">
                                                </div>
                                                <div class="des">
                                                    <div class="name">{{this.editActionPlanArray[0]?.createdname}}

                                                    </div>
                                                    <div class="role">{{this.editActionPlanArray[0]?.createdbytitle}}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-3 mt-0 pl-0">
                                <div class="f_dropdown" style="width:75%">
                                    <ul>
                                        <li style="padding: 4px 1px;cursor: default!important;">
                                            <div class="des">
                                                <div class="name ml-1" style="font-weight: normal !important;font-size: 0.7rem !important;margin-top: .4rem;">
                                                    <span style="float: left;margin-top: .1rem;margin-left: .4rem;">{{this.editActionPlanArray[0]?.apcreateddate | date: 'MM/dd/yyyy'}}</span>
                                                    <span><img src='../../../assets/images/cal_icon.png'
															style="width: 14px;margin:-0.7rem .5rem;"></span>
                                                </div>
                                                <div class="role"></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="row assigner" style="padding: 3px 0;align-items: center;">

                        <div class="col-md-2 py-0">
                            <span>Approved By:</span>
                        </div>

                        <div class="col-md-5 pl-0" *ngIf="(userInfo.id!=actionPlanCreatedId && approveId == 0 && approveStatus=='N' && userInfo.id!=approveId)">
                            <span>--N/A--</span>
                        </div>
                        <div class="col-md-5 pl-0" *ngIf="(userInfo.id==actionPlanCreatedId && approveId != 0 && approveStatus=='N' && userInfo.id!=approveId)">
                            <div class="col-md-12 pl-0">
                                <div class="c_dropdown" (click)="openActionPlanFollowers(template,'AP')">
                                    <ul>
                                        <li>
                                            <div class="">
                                                <img class="pic" src="{{userprofileimageurl}}/{{approvedBy?.sc_profile_image}}" onError="this.src='../../../assets/images/default-img.jpg'">
                                            </div>
                                            <div class="des">
                                                <div class="name">{{approvedBy?.sc_firstname}} {{approvedBy?.sc_lastname}}
                                                </div>
                                                <div class="role">{{approvedBy?.sc_title}}</div>
                                            </div>
                                        </li>
                                        <span></span>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <!-- appprove status =="Y" -->
                        <div class="col-md-5 pl-0" *ngIf="(userInfo.id!=actionPlanCreatedId && approveId != 0 )" style="display: flex;">
                            <div class="col-md-12 pl-0">
                                <div class="f_dropdown">
                                    <ul>
                                        <li>
                                            <div class="">
                                                <img class="pic" src="{{userprofileimageurl}}{{approvedBy?.sc_profile_image}}" onError="this.src='../../../assets/images/default-img.jpg'">
                                            </div>
                                            <div class="des">
                                                <div class="name">{{approvedBy?.sc_firstname}} {{approvedBy?.sc_lastname}}
                                                </div>
                                                <div class="role">{{approvedBy?.sc_title}}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <span></span>
                        </div>
                        <div class="col-md-3 mt-0 pl-0" *ngIf="( approveStatus=='Y')">
                            <div class="f_dropdown" style="width:75%;">
                                <ul>
                                    <li style="padding: 4px 1px;cursor: default !important;">
                                        <div class="des">
                                            <div class="name ml-1" style="font-weight: normal !important;font-size: 0.7rem !important;;margin-top: .4rem;">
                                                <span style="float: left;margin-top: .1rem;margin-left: .4rem;">{{approvedDate | date: 'MM/dd/yyyy'}}</span>
                                                <span><img src='../../../assets/images/cal_icon.png'
														style="width: 15px;margin:-0.7rem .5rem;"></span>
                                            </div>
                                            <div class="role"></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- appprove status =="N" -->
                        <div class="col-md-5 pl-0" *ngIf="(approveId == userInfo.id && approveStatus =='N') ">
                            <div class="col-md-12 pl-0">
                                <button type="button" class="btn btn-primary" (click)="approveActionPlanById()">Approve</button>
                            </div>
                        </div>
                        <div class="col-md-5 pl-0" *ngIf="(approveId != userInfo.id && approveStatus =='N' && approveId != 0) ">
                            <div class="col-md-12 pl-0">
                                <button type="div" style="cursor: default;" class="btn btn-primary">Waiting for
									approval</button>
                            </div>
                        </div>


                        <div class="col-md-5 pl-0" *ngIf="(userInfo.id==actionPlanCreatedId && approveId == 0)">
                            <div class="col-md-12 pl-0">
                                <div class="c_dropdown" (click)="openActionPlanFollowers(template,'AP')">

                                    <ul>
                                        <li>
                                            <div class="pic"></div>
                                            <div class="des">
                                                <div class="name">Select User</div>
                                                <div class="role">Click Here!</div>
                                            </div>
                                        </li>
                                        <span></span>
                                    </ul>
                                </div>
                            </div>
                            <!-- <div class="col-md-1 pl-lg-0 cur" (click)="openActionPlanFollowers(template,'AP')"><img
									src="assets/images/cir_sml.png"></div> -->
                        </div>
                        <div class="col-md-5 pl-0" *ngIf="(approvedBy == undefined) && actionPlantype !='E'">
                            <div class="col-md-12 pl-0">
                                <div class="c_dropdown" (click)="openActionPlanFollowers(template,'AP')">

                                    <ul>
                                        <li>
                                            <div class="pic"></div>
                                            <div class="des">
                                                <div class="name">Select User</div>
                                                <div class="role">Click Here!</div>
                                            </div>
                                        </li>
                                        <span></span>
                                    </ul>
                                </div>
                            </div>
                            <!-- <div class="col-md-1 pl-lg-0 cur" (click)="openActionPlanFollowers(template,'AP')"><img
															src="assets/images/cir_sml.png"></div> -->
                        </div>
                        <div class="col-md-5 pl-0" *ngIf="(approvedBy != undefined) && actionPlantype !='E'" style="display: flex;">
                            <div class="col-md-12 pl-0">
                                <div class="c_dropdown" (click)="openActionPlanFollowers(template,'AP')">
                                    <ul>
                                        <li>
                                            <div class="">
                                                <img class="pic" src="{{userprofileimageurl}}{{approvedBy?.sc_profile_image}}" onError="this.src='../../../assets/images/default-img.jpg'">
                                            </div>
                                            <div class="des">
                                                <div class="name">{{approvedBy?.sc_firstname}} {{approvedBy?.sc_lastname}}
                                                </div>
                                                <div class="role">{{approvedBy?.sc_title}}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <!-- <div class="col-md-4 pl-lg-0">
												<input type="date" class="form-control">
											</div> -->
                    </div>
                    <div class="row assigner" style="padding: 3px 0;align-items: center;">

                        <div class="col-md-2 py-0">
                            <span>{{actionPlantype=='E'?'Assigned To:':'Assign To:'}}</span>
                        </div>
                        <div class="col-md-5 pl-0" *ngIf="assignee != undefined && assignee.length !=0">
                            <div class="col-md-12 pl-0">
                                <div class="" [ngClass]="(userInfo.id==actionPlanCreatedId || userInfo.id== assignee?.sc_id || actionPlantype=='A' || actionPlantype=='G')?'c_dropdown':'inactive_events f_dropdown'" (click)="openActionPlanFollowers(template,'A')">
                                    <ul>
                                        <li>
                                            <div class="">
                                                <img class="pic" src="{{userprofileimageurl}}{{assignee?.sc_profile_image}}" onError="this.src='../../../assets/images/default-img.jpg'">
                                            </div>
                                            <div class="des">
                                                <div class="name">{{assignee?.sc_firstname}} {{assignee?.sc_lastname}}
                                                </div>
                                                <div class="role">{{assignee?.sc_title}}</div>
                                            </div>
                                        </li>
                                        <li>Europe</li>
                                        <li>America</li>

                                        <span></span>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mt-0 pl-0" *ngIf="assignee != undefined && assignee.length !=0 && this.assigndate">
                            <div class="f_dropdown" style="width:75%">
                                <ul>
                                    <li style="padding: 4px 1px;cursor: default!important;">
                                        <div class="des">
                                            <div class="name ml-1" style="font-weight: normal !important;font-size: 0.7rem !important;margin-top: .4rem;">
                                                <span style="float: left;margin-top: .1rem;margin-left: .4rem;">{{this.editActionPlanArray[0]?.npassignedDate | date: 'MM/dd/yyyy'}}</span>
                                                <span><img src='../../../assets/images/cal_icon.png'
														style="width: 15px;margin:-0.7rem .5rem;"></span>
                                            </div>
                                            <div class="role"></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <span></span>
                        </div>
                        <div class="col-md-5 pl-0" *ngIf="assignee == undefined || assignee.length ==0">
                            <div class="col-md-12 pl-0">
                                <div class="" [ngClass]="(userInfo.id==actionPlanCreatedId || userInfo.id== assignee?.sc_id || actionPlantype=='A' ||  actionPlantype=='G')?'c_dropdown':'inactive_events f_dropdown'" (click)="openActionPlanFollowers(template,'A')">

                                    <ul>
                                        <li>
                                            <div class="pic"></div>
                                            <div class="des">
                                                <div class="name">Unassigned</div>
                                                <div class="role">Click Here!</div>
                                            </div>
                                        </li>
                                        <span></span>
                                    </ul>
                                </div>
                            </div>
                            <!-- <div class="col-md-1 pl-lg-0 cur" (click)="openActionPlanFollowers(template,'A')"><img
									src="assets/images/cir_sml.png"></div> -->
                        </div>
                        <!-- <div class="col-md-4 pl-lg-0">
							<input type="date" class="form-control">
						</div> -->
                    </div>
                    <div class="row assigner" style="padding: 3px 0;align-items: center;">
                        <div class="col-md-2 pr-0">
                            <span>{{actionPlantype=='E'?'Followed By':'Add Followers'}}:</span>
                        </div>

                        <div class="col-md-5 pl-0" *ngIf="followers.length !=0">
                            <!-- <div [ngClass]="followers.length == 1 ? 'col-md-12 pl-0' : 'col-md-2  pl-0'"
								*ngFor="let item of followers">
								<div class="tooltip">
									<img class="prfpic" src="{{userprofileimageurl}}{{item?.sc_profile_image}}"
										onError="this.src='../../../assets/images/default-img.jpg'">
										<span class="tooltiptext">{{item?.sc_firstname}} {{item?.sc_lastname}}</span>

								</div> -->
                            <div class="tooltip" *ngFor="let item of followers"><img style="height: 40px; padding: 6%; border-radius: 50px;" src="https://devadmapi.netprophet.auto/api/scprofileimage/{{item?.sc_profile_image}}" onError="this.src='../../../assets/images/default-img.jpg'" alt="Image 1" />
                                <span class="tooltiptext">{{item?.sc_firstname}} {{item?.sc_lastname}}</span></div>
                            <!-- <div class="f_dropdown">
									<ul>
										<li>
											<div class="">
												<img class="pic" src="{{userprofileimageurl}}{{item?.sc_profile_image}}"
													onError="this.src='../../../assets/images/default-img.jpg'">
											</div>
											<div class="des">
												<div class="name" [ngClass]="followers.length == 1 ? '' : 'textWrap'">
													{{item?.sc_firstname}} {{item?.sc_lastname}}</div>
												<div class="role" [ngClass]="followers.length == 1 ? '' : 'textWrap'">
													{{item?.sc_roleName}}</div>
											</div>
										</li>
										<span></span>
									</ul>
								</div> -->
                            <!-- </div> -->
                        </div>

                        <!-- <div class="col-md-4 pl-lg-0">
							<div class="c_dropdown">
								<ul>
									<li>
										<div class="pic"></div>
										<div class="des">
											<div class="name">David Spisak</div>
											<div class="role">General Manager</div>
										</div>
									</li>
									<span></span>
								</ul>
							</div>
						</div> -->
                        <div class=" mt-0" [ngClass]="followers.length ==0 ? 'col-md-6 pl-0' : 'col-md-1'" *ngIf="followers.length ==0 ||followers.length !=0 " [ngStyle]="{'margin-left': followers.length ==0 ? '' : '-.8rem' }">
                            <!-- <div class="c_dropdown">
						
														<ul>
															<li>
																<div class="pic"></div>
																<div class="des">
																	<div class="name">David Spisak</div>
																	<div class="role">General Manager</div>
																</div>
															</li>
															<span></span>
														</ul>
													</div> -->
                            <div class="col-md-2 pl-lg-0 cur" [ngClass]="(userInfo.id==actionPlanCreatedId || userInfo.id== assignee?.sc_id || approvedBy?.sc_id)||(actionPlantype !='E')?'my-class1':'inactive_events'" (click)="openActionPlanFollowers(template,'F')"><img src="assets/images/cir_sml.png">
                            </div>
                        </div>
                        <!-- <div class="col-md-4 mt-3" *ngIf="followers.length !=0">
							<div class="col-md-1 pl-lg-0 cur" *ngFor="let item of followers;let i=index;"
								[ngClass]="(userInfo.id==actionPlanCreatedId || userInfo.id== assignee?.sc_id || approvedBy?.sc_id) && (userInfo.id != item?.sc_id)?'my-class1':'inactive_events'"
								(click)="openActionPlanFollowers(template,'F')"><img src="assets/images/cir_sml.png"
									*ngIf="(i==0) && (userInfo.id==actionPlanCreatedId || userInfo.id== assignee?.sc_id || approvedBy?.sc_id) && (userInfo.id != item?.sc_id)">
							</div>
						</div> -->
                        <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-2 p-0 text-center" style="margin-left: 4.9rem;">
                            <!-- <div class="btndark">save</div> -->
                            <input type="button" (click)="(actionPlantype != 'E')?onSubmit():onEditSubmit(pop,'T','Do you want to Mark old Action Plan as “Closed”, “Completed” or cancel this edit?')" 
                            class="btndark" value="Save" style="border:none;width:100%;font-size: .9rem;" >
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2 task_icon cur" (click)="openActionPlanTask(this.templatetask)">
                            <a routerLink="#"></a>
                            <div class="value" *ngIf="taskCount !=0">
                                {{taskCount}}
                            </div>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-4 text-center attch_icon cur">
                            <a routerLink="#"><img src="assets/images/attach1.png" alt="Attach" (click)="openActionPlanAttachments(templateattach)"> </a>
                            <div class="value" *ngIf="attachCount !=0 ">
                                {{attachCount}}
                            </div>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-4 cur" *ngIf="this.actionPlantype == 'E'">
                            <a routerLink="#"><img src="assets/images/add-msg1.png" (click)="openActionPlanmsgs(template1)" alt="Msg"> </a>
                        </div>
                        <!-- <div class="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="text-align: right;">

						</div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #template let-modal>
    <app-acton-plan-followers [followersassigned]="followers" [approvedBy]="approvedBy" [apCreatedId]="actionPlanCreatedId" [assignee]="assignee" [type]="type" (onClose)="closeModalf($event)" (onAdd)="changeImg($event)"></app-acton-plan-followers>
</ng-template>

<ng-template #templateattach let-modal>
    <app-action-plan-attach (onAddFile)="ApAttachments($event)" (onClose)="closeModalattach($event)" [actionId]="actionId" [imgdata]="selectedImgs">
    </app-action-plan-attach>
</ng-template>


<ng-template #templatetask let-modal>
    <app-action-plan-tak (onCloseTask)="onCloseTask($event)" (onAddUpdateTask)="onAddUpdateTask($event)" [actionPlanTitle]="actionPlanTitle" [actionPlanId]="actionId" [actionPlanTargetDate]="targetDate" [actionPlanTaskList]="taskAdding">
    </app-action-plan-tak>
</ng-template>

<ng-template class="popup-dialog" #pop let-modal style="top:20rem">
    <app-commo-alert [alertType]="actionPlantypeAlert" (onCloseAlert)="onCloseAlert($event,template)" [alertContent]="alertContent"></app-commo-alert>
</ng-template>

<ng-template #template1 let-modal>
    <app-action-messages (onClosemsg)="onClosemsg()"></app-action-messages>
</ng-template>

<ng-template #templateTaskadd>
    <app-taskadding [actionPlantype]="actionPlantypeTask" [actionPlanId]="actionId" [taskid]="taskid" (onClose)="closeModalf($event)" (onAdd)="changeOutput(templatetask)"></app-taskadding>
</ng-template>