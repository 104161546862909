import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, throwError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders,HttpErrorResponse } from "@angular/common/http";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";
import { AlertifyService } from './alertify/alertify.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import {catchError} from 'rxjs/operators'

declare var require: any;

// const apiconn = environment.url;
let apiconn = environment.url;
let apiconnprod = localStorage.getItem("store_id") == '001G000000fYNnXIAW' ? environment.urlProd : environment.url;
const webapi = environment.admapi;
const attachsurl = environment.AttachUrl;
const adminUrl = environment.admapi;
const apiconn1 = environment.url1;

let httpOptions: any;
// if (token != undefined) {
let localtoken = localStorage.getItem('np_token');
httpOptions = {
  headers: new HttpHeaders({
    "Accept": "application/json"
  //   "x-access-token":localtoken
  })
}

@Injectable({
  providedIn: "root"
})

export class NPApi {
  // }
  decodedToken: any;
  jwtHelper = new JwtHelperService();
  token: any;
  ipAddress:any;
  logInAct:any={​​​​​​​​}​​​​​​​​;
  userInfo:any;
  deviceInfo:any;
  private ActionPlanActualValeObs$: BehaviorSubject<any> = new BehaviorSubject("");
  constructor(private http: HttpClient, private router: Router, private alertify: AlertifyService,
    private deviceService: DeviceDetectorService,
      ) {
        apiconn = environment.url;
        apiconnprod = localStorage.getItem("store_id") == '001G000000fYNnXIAW' ? environment.urlProd : environment.url;
 }

  // socketopen() {
  //   this.socket.connect();
  // }

  // public sendMessage(message) {
  //   this.socket.emit('sendMessage', message);
  // }

  // public getmessages = () => {
  //   return Observable.create((observer) => {
  //     this.socket.fromEvent('sendMessage').subscribe((message: any) => {
  //       observer.next(message);
  //     });
  //   });
  // };

  getStoresInfo(uid) {
    let boady = {
      "id": uid,
      "expression": ""
    }
    return this.http.post(apiconn + 'stores/get', boady, httpOptions).pipe(catchError(this.handleError));
  }

  GetDash(stire_id: any, exp: any) {
    let boady = {
      "id": stire_id,
      "expression": exp.trim()
    }
    return this.http.post(apiconn + 'dashboard/get', boady, httpOptions);
  }

  GetDashsection(stire_id: any, exp: any,section:any) {
    let boady = {
      "ID": stire_id,
      "expression": exp.trim(),
      "section":section
    }
    return this.http.post(apiconnprod + 'dashboard/GetDashboardSectionWise', boady, httpOptions);
  }

  //actionPlansdashboard
  getActionPlanDashboard(objActionPlan) {
    let body = {
      id: objActionPlan.id,
      expression: objActionPlan.expression,
      searchflag: objActionPlan.searchflag,
      userid: objActionPlan.userid,
      monthyear: '',
      storeid: objActionPlan.storeid,
      deptid: objActionPlan.deptid,
      type: objActionPlan.type
    }
    return this.http.post(apiconnprod + 'actionplans/get', body, httpOptions);

  }

  // getActionPlanDashboard(objActionPlan) {
  //   let body = {
  //     id: objActionPlan.id,
  //     expression: objActionPlan.expression,
  //     searchflag: objActionPlan.searchflag,
  //     userid: objActionPlan.userid
  //   }
  //   return this.http.post('http://npapiadm.local.com/api/actionplanlabels/getactionplans', body, httpOptions);

  // }
  //getActionPlansNames

  getActioPlansNames() {
    let body = {}
    return this.http.post(apiconn + 'actionplans/getactiontemplatenames', body, httpOptions);
  }
  //getActionPlanValues
  getActionPlanVales(objActionPlanValues) {
    let body = {
      storeId: objActionPlanValues.storeId,
      section: objActionPlanValues.section,
      sectionvalue: objActionPlanValues.sectionvalue,
      monthyear: objActionPlanValues.monthyear
    }
    return this.http.post(apiconn + 'actionplans/getactionplanvalues', body, httpOptions);
  }

  // //addActionPlan
  // addActtionPlan(objActionPlanAdd) {
  //   let body = {
  //     action: objActionPlanAdd.action,
  //     AP_Title: objActionPlanAdd.AP_Title,
  //     AP_CurrentValue: objActionPlanAdd.AP_CurrentValue,
  //     AP_StartValue: objActionPlanAdd.AP_StartValue,
  //     AP_TargetValue: objActionPlanAdd.AP_TargetValue,
  //     AP_StartDate: objActionPlanAdd.AP_StartDate,
  //     AP_TargetEnddate: objActionPlanAdd.AP_TargetEnddate,
  //     AP_CreatedBy: objActionPlanAdd.AP_CreatedBy
  //   }
  //   return this.http.post(apiconn + 'actionplans/', body, httpOptions);
  // }

  //addActionPlan
  addActtionPlan(objActionPlanAdd) {
    let body = {
      action: objActionPlanAdd.action,
      AP_Title: objActionPlanAdd.AP_Title,
      AP_CurrentValue: objActionPlanAdd.AP_CurrentValue,
      AP_StartValue: objActionPlanAdd.AP_StartValue,
      AP_TargetValue: objActionPlanAdd.AP_TargetValue,
      AP_StartDate: objActionPlanAdd.AP_StartDate,
      AP_TargetEnddate: objActionPlanAdd.AP_TargetEnddate,
      AP_CreatedBy: objActionPlanAdd.AP_CreatedBy,
      AP_FollowersIds: objActionPlanAdd.AP_FollowersIds,
      AP_AssignedId: objActionPlanAdd.AP_AssignedId,
      AP_StoreId: objActionPlanAdd.AP_StoreId,
      AP_ResultValue: objActionPlanAdd.AP_ResultValue,
      AP_MonthYear: objActionPlanAdd.AP_MonthYear,
      err_no: '',
      err_no1: ''
    }
    return this.http.post(apiconn + 'actionplans/', objActionPlanAdd, httpOptions);
  }

  ViewDetails(Id: any, monthyear: any, sectionname: any) {
    let boady = {
      "id": Id,
      "section": sectionname,
      "monthyear": monthyear
    }
    return this.http.post(apiconnprod + 'detailsview/GET', boady, httpOptions);
  }

  Detailstab(Id: any, monthyear: any) {
    let boady = {
      "ID": Id,
      "month_year": monthyear
    }
    return this.http.post(apiconn + 'detailsview/GetNPDetails', boady, httpOptions);
  }

  //api/detailsview/GetNPDetails

  GetGradePercentages(Id: any, monthyear: any, sectionname: any, title: any) {
    let boady = {
      "storeid": Id,
      "section": sectionname,
      "month_year": monthyear,
      "title": title,
    }
    return this.http.post(apiconn + 'detailsview/DealerDashboardGradePercent', boady, httpOptions);
  }

  Dash_sectionGrds(Id: any, monthyear: any) {
    let boady = {
      "storeId": Id,
      "monthyear": monthyear,
    }
    return this.http.post(apiconnprod + 'dashboard/DashSectiongrds', boady, httpOptions);
  }

  //followers by store
  getFollowersByStoreValue(objStoreValue) {
    let body = {
      storeID: objStoreValue.storeID
    }
    return this.http.post(apiconn + 'actionplans/GetUsersbyStore', body, httpOptions);
  }

  //getFormaule
  getformula(obj) {
    return this.http.post(apiconn + "actionplans/Getactionplanformula", obj, httpOptions)
  }

  //addActionPlanAttachments
  addActtionPlanAttachments(objAction) {
    return this.http.post(apiconn + 'actionplans/AttachNewFiles', objAction, httpOptions)
   
  }

  deleteActtionPlanAttachments(objAction) {
    return this.http.post(apiconn + 'actionplans/DeleteAttachemt', objAction, httpOptions);
  }

  private httpOptionImageSave() {
    // this.token = localStorage.getItem("token");
    let httpOptionsForImage: any;
    // if (this.token != null) {
    return httpOptionsForImage = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        // "Access-Control-Allow-Origin":"*",
        // 'x-access-token': this.token,
      })
    }
  }
  private httpOptionProfileSave() {
    this.token = localStorage.getItem("np_token");
    let httpOptionsForImage: any;
    // if (this.token != null) {
    return httpOptionsForImage = {
      headers: new HttpHeaders({
        //"Accept": "application/json",
        ///'x-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZmlyc3RuYW1lIjoiUHJhc2FkIiwibGFzdG5hbWUiOiJDaGF2YWxpIiwidGl0bGUiOiIiLCJlbWFpbCI6InBjaGF2YWxpQHBsbGNzb2Z0d2FyZXNvbHV0aW9ucy5jb20iLCJwcmltYXJ5cGhvbmVudW1iZXIiOiI0ODAtNzE3LTc4ODQiLCJpYXQiOjE2MTIyNDk1OTksImV4cCI6MTYxMjMzNTk5OX0.1WKeah4940jd2vWs_f9ndSG9NqK_0xz8wYEZmW_vSi0",
      })
    }
  }

  getattachmenatsByid(obj) {
    return this.http.post(apiconn + "actionplans/getActionPlanAttachments", obj, httpOptions)
  }

  //taskAssign
  addAndUpdateTaskAssign(obj) {
    return this.http.post(apiconn + 'actionplans/TaskAction', obj, httpOptions);
  }

  //getTasks

  getActionPlansTask(obj) {
    return this.http.post(apiconn + 'actionplans/GetTasks', obj, httpOptions);
  }

  //taskEdit
  getEditTask(editObj) {
    return this.http.post(apiconn + 'actionplans/TaskEdit', editObj, httpOptions);
  }
  downloadFile(file): any {
    return this.http.get(attachsurl + file, { responseType: 'blob' });
  }

  //login
  loginUser(authUser){
    return this.http.post(apiconn + 'auth/signin', authUser, httpOptions);
  }
  // loginUser(authUser) {
  //   this.http.post(apiconn + 'auth/signin', authUser, httpOptions).subscribe((authData: any) => {

  //     if (authData.status == 200) {
  //       localStorage.setItem("np_token", authData.response);
  //       this.token = localStorage.getItem("np_token")
  //       this.decodedToken = this.jwtHelper.decodeToken(authData.response);
  //       this.getStoresInfo(this.decodedToken.id).subscribe((data: any) => {
  //         //let data1=data;
  //         let storeinfo = (data.status == 200) ? JSON.parse(JSON.stringify(data.response)) : [];
  //         let MonthsDdl = this.parseXml(storeinfo[0].monthsdata);
  //         let MonthsDdl1 = MonthsDdl.Months.month;
  //         localStorage.setItem('store_id', storeinfo[0].Store_Id);
  //         localStorage.setItem('monthYear', MonthsDdl1[0].month_year[0]);
  //         //  this.storeinfo=data;
  //         console.log(storeinfo, 'nj');
  //         this.router.navigate(['1'])

  //       });
  //       console.log(this.token, 'bb');
  //       //this.message = this.messagingService.currentMessage
  //       localStorage.setItem("npUserInfo", JSON.stringify(this.decodedToken));
  //       localStorage.setItem("store_id", "");
  //       localStorage.setItem("monthYear", "");
  //      // this.messagingService.requestPermission()
  //       //this.messagingService.receiveMessage()
  //     } else {
  //       this.alertify.warning('Invalid Email and Password')

  //     }

  //   });
  // }

  parseXml(xmlStr) {
    var result;
    var parser = require('xml2js');
    parser.Parser().parseString(xmlStr, (e, r) => { result = r });
    return result;
  }

  loggedIn() {
    const token = localStorage.getItem("np_token");
    //return !this.jwtHelper.isTokenExpired(token);
    if (!token) {
      return false;
    } else {
      return true;
    }
  }

  getToken() {
    return localStorage.getItem('np_token');
  }

  logout() {
    //this.getLocation('logout');
    localStorage.removeItem('np_token');
    localStorage.removeItem('npUserInfo');
    localStorage.removeItem('store_id');
    this.router.navigate(['/']);
  }
  passwordReset(passwordObj) {
    return this.http.post(apiconn + 'auth/resetpassword', passwordObj, httpOptions);

  }
  profileUpdating(profileObj) {
    return this.http.post(apiconn + 'storecontacts/profileupdate', profileObj, this.httpOptionProfileSave());
  }

  //approveActionPlan
  approveActionPlan(obj) {
    return this.http.post(apiconn + 'actionplans/ActionPlanApprove', obj, httpOptions);
  }

  //achieveActionPlan
  achieveActionPlan(obj) {
    return this.http.post(apiconn + 'actionplans/ActionPlanAchieve', obj, httpOptions);
  }

  //dashBoard Grid Icon
  getDashBoardGridIcons(gridObj) {
    return this.http.post(apiconn + 'groups/getDashboardIconGrid', gridObj, httpOptions);
    
  }
  //stores Grid Icon
  getStoresGridIcons(gridObj) {
    return this.http.post(apiconnprod + 'groups/getIconGridIndividual', gridObj, httpOptions);
  }

  //recommended action plans
  GetPotentialActionPlans(body) {
    return this.http.post(apiconnprod + 'actionplans/GetPotentialActionPlans', body, httpOptions);
  }

  GetStoresconactsJson() {
    return this.http.get('/assets/Storeusers.json',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'text/json')
          .append('Access-Control-Allow-Methods', 'GET')
          .append('Access-Control-Allow-Origin', '*')
          .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
        responseType: 'text'
      })
  }

  ForgotPassword(body) {
    return this.http.post(adminUrl + 'storecontacts/getEmail', body, httpOptions);
  }

  Restpassword(body) {
    return this.http.post(apiconn + 'auth/forgotPassword', body, httpOptions);
  }

  GetAPLabelsJson() {
    return this.http.get('/assets/aplabels.json',
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'text/json')
          .append('Access-Control-Allow-Methods', 'GET')
          .append('Access-Control-Allow-Origin', '*')
          .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
        responseType: 'text'
      })
  }

  getTrendDetails(Id: any, monthyear: any, sectionname: any) {
    let boady = {
      "ID": Id,
      "section": sectionname,
      "monthyear": monthyear
    }
    return this.http.post(apiconn + '/dashboard/getTrendDetails', boady, httpOptions);
  }

  getPerformanceDetail(monthyear, storeId) {
    let body = {

      "storeid": storeId,
      "monthyear": monthyear
    }
    return this.http.post(apiconn + 'performance/GetPerformanceDetails', body, httpOptions);
  }

  getUsersData(objStoreValue) {
    let body = {
    storeID: objStoreValue.storeID
    }
    return this.http.post(apiconn +'storecontacts/getstoreusersjson', body, httpOptions);
  }

  getWeightedAvgs(boady){
    return this.http.post(apiconn + 'dealersetup/GetWeightedAvg', boady, httpOptions);    
   }

  getWorkingDays(boady){    
      return this.http.post(apiconn + 'dealersetup/getdealerdays', boady, httpOptions); 
    }

    saveDealersWorkingDays(data)
    {
    console.log(data);
    return this.http.post(apiconn + 'dealersetup/auddealerdaysaction',data,httpOptions);
    }


   
  getDealerInfo(boady){   
      return this.http.post(apiconn + 'dealersetup/GetDealerInfo', boady, httpOptions); 
    }

  saveDealersdata(data)
   {
   console.log(data);
   return this.http.post(apiconn + 'dealersetup/storeinfoaction',data,httpOptions);
   }

  saveWeightedavgData(data)
  {
  console.log(data);
  return this.http.post(apiconn + 'dealersetup/WeightedAvgVarianceCalcAction',data,httpOptions);
  }

  getMultipliersData(boady){
    return this.http.post(apiconn + 'dealersetup/GetValuationMultiplier', boady, httpOptions);    
   }

   getMultipliersbyuserid(boady){
    return this.http.post(apiconn + 'dealersetup/GetmultipliersbyUid', boady, httpOptions);    
   }

   saveMultiplierdata(data)
   {
   console.log(data);
   return this.http.post(apiconn + 'dealersetup/multipliersaction',data,httpOptions);
   }

   getValuationDetail(monthyear, storeId) {
    let body = {
 
      "storeId": storeId,
      "monthyear": monthyear
    }
    return this.http.post(apiconn + 'performance/GetValuationData', body, httpOptions);
  }

  GetDbPerformance(monthyear, storeId) {
    let body = {
 
      "storeid": storeId,
      "monthyear": monthyear
    }
    return this.http.post(apiconn + 'performance/GetDbPerformance', body, httpOptions);
  }
  
  APCurrentTrends(body) {
    return this.http.post(apiconn + 'actionplans/GetActionPlanCurrentTrends', body, httpOptions);
  }
  
  getValuationDetailFilter(monthyear, storeId, filter) {
    let body = { 
      "storeId": storeId,
      "monthyear": monthyear,
      "flag": filter
    }
    return this.http.post(apiconn + 'performance/GetValuationDatabyfilter', body, httpOptions);
  }

  getallstores(){
    return this.http.post(apiconn +'dealersetup/getallstoresinfo', httpOptions); 

  }
  getallstoresbyuid(id){
    let body = { 
      "sc_id": id,
         }
    return this.http.post(apiconn +'storecontacts/GetStorenameWithUid', body,httpOptions); 

  }
  getallroles(boady){
    return this.http.post(apiconn +'roles/get',boady, httpOptions); 

  }
  addingroles(boady){
    return this.http.post(apiconn +'roles/aud',boady, httpOptions); 

  }
  addinguser(boady){
    return this.http.post(apiconn +'storecontacts/aud',boady, httpOptions); 

  }

  getGroupsdata(boady){    
    return this.http.post(apiconn + 'dealergroups/get', boady, httpOptions); 
  }
  getBrandsdata(boady){    
    return this.http.post(apiconn + 'brands/getbrandsbyname', boady, httpOptions); 
  }

  getPrivileges(){
    return this.http.post(apiconn +'previleges/get', httpOptions); 

  }
  addingprivilege(boady){
    return this.http.post(apiconn +'previleges/aud',boady, httpOptions); 

  }
  // task
  gettaskpriority(boady){
 
    return this.http.post(apiconn + '/tasks/gettaskfeeds', boady, httpOptions); 
    }
    gettaskbyid(boady){
 
      return this.http.post(apiconn + '/tasks/gettaskfeedsbyid', boady, httpOptions); 
      }
      taskcomplete(boady){
      
      return this.http.post(apiconn + '/tasks/taskupdate', boady, httpOptions); 
      }
      gettaskfilter(boady){
 
        return this.http.post(apiconn +'tasks/gettaskfilters', boady, httpOptions); 
        }

        //Login activity
        lgoinactivity()
        {
          if (navigator.geolocation) {​​​​​​​​
            navigator.geolocation.getCurrentPosition(this.showPosition);
             }​​​​​​​​ else {​​​​​​​​
            console.log("Geolocation is not supported by this browser.");
             }​​​​​​​​
        }

        getLocation(typ) {​​​​​​​​
 
         
           
          this.http.get("https://api.ipify.org/?format=json").subscribe( res=> {​​​​​​​​
          this.ipAddress=res['ip'];
          this.logInAct.Ipaddress=this.ipAddress;
          console.log('IP Address :', this.logInAct);
          this.userInfo=JSON.parse(localStorage.getItem('npUserInfo'));
          this.epicFunction(typ);
           }​​​​​​​​);
           }​​​​​​​​
           
          showPosition(position) {​​​​​​​​
          localStorage.setItem('lat', position.coords.latitude);
          localStorage.setItem('long', position.coords.longitude); 
           }​​​​​​​​

           
epicFunction(type){​​​​​​​​
  this.deviceInfo=this.deviceService.getDeviceInfo();
  this.logInAct.Ipaddress=this.ipAddress;
  this.logInAct.deviceType=this.deviceInfo.deviceType;
  this.logInAct.browserType=this.deviceInfo.browser;
  this.logInAct.deviceOs=this.deviceInfo.os;
  this.logInAct.devicOsVersion=this.deviceInfo.os_version;
  this.logInAct.logType=type;
  this.logInAct.Lat=localStorage.getItem('lat');
  this.logInAct.Lon=localStorage.getItem('long');
  var postObj= {​​​​​​​​
  "action":"A",
  "user_Id":this.userInfo.id,
  "IP_address":this.logInAct.Ipaddress,
  "latitude":this.logInAct.Lat,
  "longitude":this.logInAct.Lon,
  "device_type":this.logInAct.deviceType,
  "device_OS":this.logInAct.deviceOs,
  "log_type":this.logInAct.logType,
  "deviceOsVersion":this.logInAct.devicOsVersion,
  "browser_type" :this.logInAct.browserType
   }​​​​​​​​;
  localStorage.setItem('deviceinfo', JSON.stringify(postObj));
  console.log('Ip data :', postObj);
  // this.http.post(apiconn + 'auth/loginactivitiesaction', postObj, httpOptions ).subscribe((data: any) => {​​​​​​​​
  // console.log('User Login Activities : ', data);
  this.http.post(apiconn+'auth/loginactivitiesaction', postObj, httpOptions ).subscribe((data:any) => {​​​​​​​​
  console.log('User Login Activities : ', data);
   }​​​​​​​​);
   
   }​​​​​​​​
   getGroupsdatabyuserid(boady){    
    return this.http.post(apiconn + 'dealergroups/GetDealerGroupsByUserID', boady, httpOptions); 
  }
  addingcustomgroup(boady){
    return this.http.post(apiconn +'dealergroups/customgroupsaction',boady, httpOptions); 
 
  }
  getuserdetails(boady){
    return this.http.post(apiconn +'storecontacts/get',boady, httpOptions); 
    
    }
    GetStatesXml() {
      return this.http.get('/assets/States.xml',
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'text/xml')
            .append('Access-Control-Allow-Methods', 'GET')
            .append('Access-Control-Allow-Origin', '*')
            .append('Access-Control-Allow-Headers', "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"),
          responseType: 'text'
        })
    }

     //Task and messenger data
     Gettaskandmessenger(body) {
      return this.http.post(apiconn + 'tasks/GetDbTaskandMessenger', body, httpOptions);
    }

    
savebookmark(data)
{
return this.http.post(apiconn + 'dashboard/UserBookMarks',data,httpOptions);
}

Getbookmarkslist(body) {
  return this.http.post(apiconn + 'dashboard/GetUserBookMarks', body, httpOptions);
}

getActionPlanids(objActionPlan) {
  let body = {
    storeid: objActionPlan.storeid
  }
  return this.http.post(apiconn + 'actionplans/GetActionPlanLabelsbyStore', body, httpOptions);

}
// get all modules list
getModulesList(){
  return this.http.post(apiconn +'previleges/GetAllAccessModules', httpOptions); 
}

// get modules list by role
getModulesListbyrole(objid){
  let body = {
    ID: objid.ID
  }
  return this.http.post(apiconn +'previleges/GetAccessModulesByRole', body ,httpOptions); 
}

// saving module permissions for role
modulePermissionsAction(boady){
  return this.http.post(apiconn +'previleges/NPModulePermissionsAction',boady, httpOptions); 
}

handleError(error) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
  } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  console.log(errorMessage);
  return throwError(errorMessage);
}

GetallReports(body){
  return this.http.post(apiconn +'reports/GetNPAIReport', body, httpOptions);
 }

 rolestoreupdate(obj){
  return this.http.post(apiconn +'roles/RolestoreUpdate',obj, httpOptions);
}


    saveuserroleslist(data){​
return this.http.post(apiconn + 'roles/RolesListUpdate', data,httpOptions);
  }​

    getoverallgradecalc(obj){​
return this.http.post(apiconn +'performance/OveralGradeCalc',obj, httpOptions);
  }​
  getActionPlanActualValueObs(): Observable<any> {
    return this.ActionPlanActualValeObs$.asObservable();
  }

  setActionPlanActualValueObs(obj: any) {
    //console.log(obj, 'ji');

    this.ActionPlanActualValeObs$.next(obj);
  }


  Get_dealer_hrs(body){

    return this.http.post(apiconn +'dealergroups/getdealerhours', body, httpOptions);

  }



  save_dealer_hrs(body){

    return this.http.post(apiconn +'dealergroups/dealerhours', body, httpOptions);    

  }



   Get_holidays(body){

    return this.http.post(apiconn +'dealergroups/GetStoreHolidays', body, httpOptions);

  }

    

  ADD_holidays(body){   

    return this.http.post(apiconn +'dealergroups/AUDStoreHolidays', body, httpOptions);    

  }

  
         GetDealerStartday(body){    return this.http.post(apiconn +'dealergroups/GetDealerStartday', body, httpOptions);        }  
      DealerStartdayAction(body){        return this.http.post(apiconn +'dealergroups/DealerStartdayAction', body, httpOptions);        }

  getfeloginuserActivity(data){
    return this.http.post(webapi + 'auth/getfrontendloginactivites', data, httpOptions)
  }

  GetInsightsData(data){
    return this.http.post(apiconn + '/insights/GetInsightsData', data, httpOptions)
  }

  GethotnotsectionData(data){
    return this.http.post(apiconn + '/hotnot/GetSectionlabels', data, httpOptions)
  }

  dashboardpace(){
    let data={}

    return this.http.post(apiconn + '/dashboard/GetDashboardPace',data, httpOptions);
  }

  GetDashboardPaceByStore(id,monthYear)
  {
    let body={
      "storeid":id,
      "monthyear":monthYear
  }
    return this.http.post(apiconn + '/dashboard/GetDashboardPaceByStore',body, httpOptions);
      }

      Getnotifications(data){

        return this.http.post(apiconn + '/notifications/getnotifications', data, httpOptions)
    
      }


      ValuationValue(Id,monthyear){   
             let boady = { "storeid": Id, "monthyear": monthyear }      
          return this.http.post(apiconn + 'performance/GetValuationDataCurrentMnth', boady, httpOptions);    
        }
      
      getuserstatics(data){
        return this.http.post(apiconn + 'reports/GetUserStatistics',data,httpOptions)
      }
      


    private storeValueObs$: BehaviorSubject<any> = new BehaviorSubject('')
    setStoreValue(store_id: any){
      this.storeValueObs$.next({store_id:store_id})
    }

    getStoreValue(){
      return this.storeValueObs$.asObservable();
    }

    MessangerUnreadStatusCount(data){
      return this.http.post( apiconn1+'/MessangerUnreadStatusCount',data,httpOptions)  
     }
      

     uploadFile(data){
      return this.http.post(apiconn+'reports/sendreports',data,this.httpOptionProfileSave())  
     }


StoreContactStatus(obj1){ 
//  return this.http.post('http://devapi.netprophet.auto/sq/api/storecontacts/StoreContactStatus',obj1,httpOptions)    
    return this.http.post(apiconn + 'storecontacts/StoreContactStatus',obj1,httpOptions) }


    workingDays(obj){
        return this.http.post(apiconn+'dealersetup/GetworkingDays',obj,httpOptions)
    }

    getReportsPerformance(obj){
      return this.http.post(apiconn+'reports/GetGEPdata',obj,httpOptions)
    }

    getInsights(data){

      // return this.http.post(apiconn + 'insights/GetInsights', data,httpOptions)
      return this.http.post('https://appapi.netprophet.auto/sq/api/insights/GetInsightsTitles', data,httpOptions)
    }

     getValuation(data){

      return this.http.post(apiconn + 'performance/GetValuationByMultiplier', data,httpOptions)

    }

   


// billing

getBillingDeatails(obj){
  return this.http.post(apiconn+'billing/GetBillingAccountDetails',obj,httpOptions)
}
getBillingHistory(obj){
  return this.http.post(apiconn+'billing/GetBillingHistory',obj,httpOptions)
}
saveCardDetails(obj){
  return this.http.post(apiconn+'billing/CardPayment',obj,httpOptions)
}

saveACHAccountDetails(obj){
  return this.http.post(apiconn+'billing/AccountPayment',obj,httpOptions)
}

getCardDetails(obj){
  return this.http.post(apiconn+'billing/getdealerPayments',obj,httpOptions)
}
setAsDefaultCard(obj){
  return this.http.post(apiconn+'billing/SetCardAsDefault',obj,httpOptions);
}
submitPaymentDetails(obj){
  return this.http.post(apiconn+'billing/billingAud',obj,httpOptions);
}

verifyACHAccount(obj){
  return this.http.post(apiconn+'billing/VerifyBankAccount',obj,httpOptions);
}

getAllMigrationCount() {
  return this.http.post(adminUrl + 'storecontacts/GetAllMigrationCount',  httpOptions)
}

AccountDetailsAction(obj){
  return this.http.post(apiconn+'billing/AccountDetailsAction',obj,httpOptions);
}
GetAccountDetails(storeId: string): Observable<any> {
  return this.http.post(apiconn + 'billing/GetAccountDetails', { storeId });
}

directSignIn(obj:any){
  return this.http.post(apiconn + 'auth/SigninAxelone',obj, httpOptions);
}

sendinvoiceNow(obj){
  return this.http.post(apiconn+'billing/InvoiceMail',obj,httpOptions)
}

saveCardEntryDetails(obj){
  return this.http.post(apiconn+'billing/InsertCardEntryDetails',obj,httpOptions)
}

}
    
