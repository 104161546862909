import { Component, Input, Output, OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Common } from 'src/app/common';

@Component({
  selector: 'dropdownlist',
  templateUrl: './dropdownlist.component.html',
  styleUrls: ['./dropdownlist.component.scss']
})
export class DropdownlistComponent implements OnInit, OnChanges {

  @Input() options: any = [];
  @Input() optionValue: string;
  @Input() optionText: string;
  @Input() optionImg: string;
  //@Input() dropDownLabel: string; 
  @Output() onValueChanged = new EventEmitter<any>();
  @Input() selectedOption: any = {}
  selectedValue: any;
  selectedText: any;
  defaultOption: any = [];
  openBlockDiv:boolean = false;
  CopiedOptions: any = [];
  searchInput:string = "" ;
  selectedImg:string = "";
  apiconn1: string = environment.ImgUrl;

  constructor(public comm: Common,) { }

  ngOnInit(): void {
  }

  ddlchange(change_ddl) {
    this.selectedValue = change_ddl[this.optionValue]
    this.selectedText = change_ddl[this.optionText]
    this.selectedImg = change_ddl[this.optionImg]
    console.log(this.selectedImg);
    this.onValueChanged.emit(change_ddl[this.optionValue]);
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(this.options,'regergetgteg');
    console.log(changes.selectedOption,'regergetgtegfgcbfgb5675');
    this.openBlockDiv = false;
    this.CopiedOptions = this.options;
    if (typeof changes.selectedOption !== 'undefined') {

      if (typeof changes.selectedOption.currentValue !== 'undefined') {

        if (typeof changes.selectedOption.currentValue[this.optionText] !== 'undefined') {
          this.selectedValue = changes.selectedOption.currentValue[this.optionValue]
          this.selectedText = changes.selectedOption.currentValue[this.optionText]
          this.selectedImg = changes.selectedOption.currentValue[this.optionImg]
        }
      }
    }

  }

  fetchSeries(ev: any){
    if(ev.target.value === ' ') {
      return this.options = []
    }
    else{
      console.log('usdhcjhgsvjyhfsvjydfgvjydgxvjygdfjvgfjvgsjdvhgf');
      this.CopiedOptions = this.options;
      this.CopiedOptions = this.CopiedOptions.filter(item => {
        // return item[this.optionText].toLowerCase().startsWith(ev.target.value.toLowerCase())
        return item[this.optionText].toLowerCase().includes(ev.target.value.toLowerCase())
      })
    } 
}

OpenBlock(){
  this.searchInput = "";
  this.CopiedOptions = this.options;
  console.log(this.options);
}

}
